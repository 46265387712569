import React, { useEffect, useState } from "react"
import Videocard from "../../../components/VideoCard"
import "../../../assets/styles/page/docVideos.scss"
import { Link } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo/seo"

interface Video {
  videoThumbnail: {
    url: string
  }
  videoTitle: string
  slug: string
  metaTitle: string
  videoCategory: string
  _id: string
  seo: {
    title: string
    description: string
    image: {
      url: string
    }
  }
}

const Videos = () => {
  const [videos, setVideos] = useState<Video[]>([])
  const [metaData, setMetaData] = useState<Video["seo"] | null>(null)

  useEffect(() => {
    const fetchVideos = async () => {
      const query = `
        query videoInfos {
          videoCollection {
            items {
              ... on Video {
                videoTitle
                slug
                metaTitle
                videoCategory
                _id
                videoThumbnail {
                  url
                }
              }
              seo {
                ... on Seo {
                  description
                  title
                  image {
                    url
                  }
                }
              }
            }
          }
        }
      `
      try {
        const { default: ContentfulService } = await import("../../../services/contentful.service")
        const response = await ContentfulService.callContentfulApi(query)
        const videoData = response.data.videoCollection?.items || []
        setVideos(videoData)
        setMetaData(videoData[0]?.seo)
      } catch (error) {
        console.error("Error fetching videos:", error)
      }
    }

    fetchVideos()
  }, [])

  if (!videos.length || !metaData) {
    return <div>Loading...</div>
  }

  return (
    <>
      <SEO title={metaData.title} description={metaData.description} image={metaData.image.url} />
      <Layout>
        <div className="videos--page--cointainer">
          <div className="video--header">
            <h1 className="videos--title">Videos</h1>
            <p className="videos--title--subtitles">
              Experience our talks and tutorials,and get behind-the-scenes look at our team
            </p>
          </div>
          <div className="video--list">
            {videos.map(video => {
              const { videoThumbnail, videoTitle, slug, metaTitle, videoCategory, _id } = video
              return (
                <Link to={`/doc/article/doc-videos/${slug}`} className="video--card--wrapper--container" key={_id}>
                  <Videocard
                    key={_id}
                    thumbnail={videoThumbnail.url}
                    videotitle={videoTitle}
                    videodes={metaTitle}
                    meta={videoCategory}
                  />
                </Link>
              )
            })}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Videos
